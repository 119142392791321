/// <reference types="@types/google.maps" />
import { useEffect, useReducer, useState } from 'react';

import clsx from 'clsx';
import GoogleMap from 'google-maps-react-markers';
import Link from 'next/link';
import * as ReactDOMServer from 'react-dom/server';

import { Store, StoreCoords } from '@hultafors/shared/types';

import ExternalLinkSvg from './external_link.svg';
import { MapChangedData } from './map-changed-data';
import styles from './map.module.scss';
import PlaceTagSvg from './place_tag.svg';

interface MapProps {
  children?: React.ReactNode;
  center?: StoreCoords;
  zoom?: number;
  stores?: Store[];
  onStoreSelect?(store: Store): void;
  viewOnMap?: string;
  visitWebsiteLabel?: string;
  sticky?: boolean;
}

export const Map: React.FC<MapProps> = ({
  center: defaultCenter = {
    longitude: 12.7388379,
    latitude: 57.6983323,
  },
  zoom = 5,
  stores = [],
  visitWebsiteLabel,
  viewOnMap,
  sticky,
}) => {
  const apiKey = process.env['NEXT_PUBLIC_GOOGLE_API_KEY'] || '';
  const [map, setMap] = useState<google.maps.Map>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  interface State {
    markers: google.maps.Marker[];
  }

  function reducer(
    previous: State,
    action: { type: keyof State; value: google.maps.Marker[] },
  ): State {
    if (action.type === 'markers') {
      previous.markers?.forEach((item) => item.setMap(null));
      return {
        ...previous,
        markers: action.value,
      };
    }
    return { ...previous };
  }

  const [, dispatch] = useReducer(reducer, {
    markers: [],
  });

  const options: google.maps.MapOptions = {
    disableDefaultUI: true,
  };

  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({
    map: mapLib,
  }: {
    map: google.maps.Map;
    maps?: typeof google.maps;
  }) => {
    setMap(mapLib);
  };

  useEffect(() => {
    if (map && !infoWindow) {
      setInfoWindow(
        new google.maps.InfoWindow({
          content: '',
          ariaLabel: '',
        }),
      );
    }
  }, [map]);

  useEffect(() => {
    if (!map || !stores.length || !apiKey || !infoWindow) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    const newMarkers: google.maps.Marker[] = [];

    stores.forEach(({ mapLink, data: { longitude, latitude, ...data } }) => {
      if (longitude && latitude) {
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        };

        const marker = new google.maps.Marker({
          position,
          map,
          title: data.companyName,
        });

        marker.addListener('click', () => {
          const jsx = (
            <div className={styles['info-window']}>
              <p className={styles['title']}>{data.companyName}</p>
              <p className={styles['styles']}>
                <span>{data.address1}</span>
                <span>{data.address2}</span>
                <span>
                  {data.zipCode}
                  {' '}
                  {data.city}
                </span>
              </p>
              {mapLink && viewOnMap && (
                <p className={styles['map-link']}>
                  <Link
                    href={mapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles['info-link']}
                  >
                    <PlaceTagSvg
                      aria-hidden={true}
                      focusable={false}
                      width={12}
                      height={12}
                    />
                    {viewOnMap}
                  </Link>
                </p>
              )}
              {data.webSite && (
                <p className={styles['website']}>
                  <Link href={data.webSite} className={styles['info-link']}>
                    {visitWebsiteLabel || data.webSite}
                    <ExternalLinkSvg
                      aria-hidden={true}
                      focusable={false}
                      width={12}
                      height={12}
                    />
                  </Link>
                </p>
              )}
            </div>
          );
          const content = ReactDOMServer.renderToString(jsx);

          infoWindow?.setContent(content);
          infoWindow?.open({
            anchor: marker,
            map,
          });
        });

        newMarkers.push(marker);

        bounds.extend(position);
      }
    });

    dispatch({ type: 'markers', value: newMarkers });
    if (map?.fitBounds && bounds) {
      map.fitBounds(bounds);
    }
  }, [stores, map, infoWindow]);

  function onChange(_data: MapChangedData) {
    // TODO handle map change
  }

  if (!apiKey) {
    // TODO Render some nice error when there is no map to show
    console.error('Google Maps Api key missing!');
    return null;
  }

  return (
    <div className={clsx(styles['container'], sticky && styles['sticky'])}>
      <GoogleMap
        apiKey={apiKey}
        defaultCenter={{
          lng: parseInt(`${defaultCenter.longitude}`, 10),
          lat: parseInt(`${defaultCenter.latitude}`, 10),
        }}
        defaultZoom={zoom}
        options={options}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={onChange}
      />
    </div>
  );
};
