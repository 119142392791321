import React, { useId } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as RadixSelect from '@radix-ui/react-select';
import clsx from 'clsx';

import { SelectItem } from '../select-item/select-item';

import styles from './select.module.scss';

import type { SelectProps as RadixSelectProps } from '@radix-ui/react-select';

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface SelectOptionGroup {
  label?: string;
  items: SelectOption[];
}

type SelectSeparator = 'separator';

type SelectOptionType = SelectOption | SelectOptionGroup | SelectSeparator;

interface SelectProps extends RadixSelectProps {
  options: SelectOptionType[];
  onChange?(value: string): void;
  label: string;
  placeholder?: string | null;
  className?: string;
}

export const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  options,
  className,
  ...props
}) => {
  const uid = useId();
  const baseKey = `Select-${uid}`;

  const optionMapper = (option: SelectOptionType, index: number) => {
    const key = `${baseKey}-option-${index}`;
    if (typeof option === 'string') {
      return (
        <RadixSelect.Separator key={key} className={styles['separator']} />
      );
    }

    if ('value' in option) {
      return (
        <SelectItem key={key} value={option.value} disabled={option.disabled}>
          {option.label}
        </SelectItem>
      );
    }

    if ('items' in option) {
      return (
        <RadixSelect.Group key={key} className={styles['group']}>
          {option.label && (
            <RadixSelect.Label className={styles['label']}>
              {option.label}
            </RadixSelect.Label>
          )}
          {option.items.map(optionMapper)}
        </RadixSelect.Group>
      );
    }

    return null;
  };

  return (
    <fieldset className={clsx(styles['fieldset'], className)}>
      <RadixSelect.Root {...props}>
        <RadixSelect.Trigger className={styles['trigger']} aria-label={label}>
          <RadixSelect.Value placeholder={placeholder} />
          <RadixSelect.Icon className={styles['icon']}>
            <ChevronDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content className={styles['content']}>
            <RadixSelect.ScrollUpButton className={styles['scroll-button']}>
              <ChevronUpIcon />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className={styles['viewport']}>
              {options.map(optionMapper)}
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className={styles['scroll-button']}>
              <ChevronDownIcon />
            </RadixSelect.ScrollDownButton>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    </fieldset>
  );
};
