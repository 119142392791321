import { useId } from 'react';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';

import styles from './checkbox.module.scss';

import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
interface CheckBoxProps extends RadixCheckboxProps {
  children?: string | null;
}

export const Checkbox: React.FC<CheckBoxProps> = ({
  children,
  id,
  className,
  ...props
}) => {
  const uid = useId();
  const htmlId = id || `Checkbox-${uid}`;

  return (
    <fieldset className={clsx(styles['fieldset'], className)}>
      <RadixCheckbox.Root className={styles['root']} id={htmlId} {...props}>
        <RadixCheckbox.Indicator className={styles['indicator']}>
          <CheckIcon />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label className={styles['label']} htmlFor={htmlId}>
        {children}
      </label>
    </fieldset>
  );
};
