import React, { startTransition, useId, useMemo, useState } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';

import {
  findRetailersMode,
  useFindRetailers,
  useOnlineStores,
} from '@hultafors/shared/api';
import { Store, StoreCoords } from '@hultafors/shared/types';

import sharedStyles from '../../style/shared.module.scss';
import ClearSvg from '../../svg/clear.svg';
import ExternalLinkSvg from '../../svg/external-link.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';
import SearchSvg from '../../svg/search.svg';
import { Accordion } from '../accordion/accordion';
import { AccordionItem } from '../accordion-item/accordion-item';
import { Checkbox } from '../checkbox/checkbox';
import { Loader } from '../loader/loader';
import { Select } from '../select/select';
import { Tabs } from '../tabs/tabs';

import styles from './find-retailers.module.scss';

import type { AccordionItemClassNames } from '../accordion-item/accordion-item';

const Map = dynamic(
  () => import('../map/map').then((mod) => mod.Map),
  {
    ssr: false,
  },
);

export interface FindRetailersClassNames {
  searchContainer?: string | null;
  searchInput?: string | null;
  searchOptions?: string | null;
  resultContainer?: string | null;
  resultStores?: string | null;
  resultList?: string | null;
  resultMap?: string | null;
}

export interface FindRetailersTranslations {
  clear?: string | null;
  matchesFor?: string | null;
  noOnlineStoresFound?: string | null;
  noResultFor?: string | null;
  noResultForYourLocation?: string | null;
  onlineStores?: string | null;
  otherCountriesIncluded?: string | null;
  radiusHelpText?: string | null;
  radiusLabel?: string | null;
  radiusPlaceholder?: string | null;
  resultBasedOnYourLocation?: string | null;
  retailers?: string | null;
  searchFieldLabel?: string | null;
  searchFieldPlaceholder?: string | null;
  searchSubmitLabel?: string | null;
  storeAlsoSells?: string | null;
  viewOnMap?: string | null;
  visitWebsite?: string | null;
}

interface FindRetailersProps {
  searchTerm?: string | null;
  radius?: number | null;
  hideOnlineStores?: boolean | null;
  hideSearchInputs?: boolean | null;
  defaultCoords?: StoreCoords | null;
  className?: string;
  style?: React.CSSProperties;
  translations: FindRetailersTranslations;
  classNames?: FindRetailersClassNames;
  indicator?: React.ReactNode;
  showInternational?: boolean;
  deepLink?: boolean;
}

const DEFAULT_RADIUS = 50;

const radiusOptions = [
  Math.floor(DEFAULT_RADIUS / 2),
  DEFAULT_RADIUS,
  DEFAULT_RADIUS * 2,
];

const globalDefaultCoords: StoreCoords = {
  longitude: 12.7388379,
  latitude: 57.6983323,
};
export const FindRetailers: React.FC<FindRetailersProps> = ({
  radius: radiusInput,
  searchTerm,
  defaultCoords,
  translations,
  className,
  style,
  hideOnlineStores,
  hideSearchInputs,
  classNames,
  indicator,
  showInternational,
  deepLink,
}) => {
  const uid = useId();
  const {
    term,
    setTerm,
    international,
    setInternational,
    radius,
    setRadius,
    mode,
    stores,
    loading,
    clear,
    setCoords,
    coords,
    geoLoading,
  } = useFindRetailers({
    defaultRadius:
      radiusInput && radiusOptions.includes(radiusInput)
        ? radiusInput
        : DEFAULT_RADIUS,
    radiusOptions,
    defaultCoords: defaultCoords ?? globalDefaultCoords,
    defaultTerm: searchTerm || '',
    deepLink,
  });
  const { stores: onlineStores = [], loading: onlineStoresLoading } = useOnlineStores();
  const [search, setSearch] = useState<string>(term || '');
  const [selectedItem, setSelectedItem] = useState<Store | undefined>(
    undefined,
  );
  const message = useMemo(() => {
    if (loading) {
      return '';
    }
    if (mode === findRetailersMode.findRetailersByCoord && !stores.length) {
      return translations.noResultForYourLocation || '';
    }
    if (mode === findRetailersMode.findRetailersByCoord && stores.length) {
      return `${stores.length} ${translations.matchesFor || ''}`;
    }
    if (!term) {
      return '';
    }
    if (mode === findRetailersMode.findRetailers && !stores.length) {
      return translations.noResultForYourLocation || '';
    }
    if (mode === findRetailersMode.findRetailers && stores.length) {
      return `${stores.length} ${translations.matchesFor || ''} "${term}"`;
    }
    return '';
  }, [loading, term, mode, stores, translations]);

  const onChangeTerm: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    startTransition(() => {
      setSearch(event.target.value);
    });
  };

  const clearSearch = () => {
    startTransition(() => {
      setSearch('');
      clear();
    });
  };

  const onSubmitSearch = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    startTransition(() => {
      setTerm(search);
    });
  };

  const onAccordionChange = (value: string) => {
    startTransition(() => {
      setSelectedItem(stores?.find((x) => x.id === value) || selectedItem);
    });
  };

  const mapPositionChanged = async (latitude: number, longitude: number) => {
    startTransition(() => {
      setCoords({ latitude, longitude });
      setTerm('');
    });
  };

  const accordionItemClassNames: AccordionItemClassNames = {
    content: styles['accordion-content'],
  };

  const storeMapper = (item: Store) => {
    if (!item?.data) {
      return null;
    }
    const value = item.id;
    const key = `FindRetailers-${uid}-store-${value}`;

    const addressMapper = (line: string | undefined | null, i: number) => {
      if (!line) {
        return null;
      }
      const addressKey = `${key}-address-line-${i}`;
      return (
        <span className={styles['address-line']} key={addressKey}>
          {line}
        </span>
      );
    };

    const label = (
      <span className={styles['accordion-label']}>
        <span className={styles['accordion-title']}>
          {item.data.companyName}
        </span>
        <span className={styles['accordion-subtitle']}>{item.data.city}</span>
      </span>
    );

    const city
      = [item.data.zipCode, item.data.city].filter(Boolean).join(' ') || '';
    const address = [item.data.address1, city, item.data.country].filter(
      Boolean,
    );

    return (
      <AccordionItem
        label={label}
        key={key}
        value={value}
        indicator={indicator}
        classNames={accordionItemClassNames}

      >
        <div className={styles['location']}>
          <div className={styles['address']}>{address.map(addressMapper)}</div>

          {item.mapLink && (
            <Link
              href={item.mapLink}
              target="_blank"
              rel="noopener noreferrer"
              className={clsx(styles['link'], styles['icon-link'])}
            >
              <PlaceTagSvg aria-hidden={true} focusable={false} width={16} />
              {translations.viewOnMap || item.mapLink}
            </Link>
          )}
        </div>
        {!!(item.data.emailAddress || item.data.phone) && (
          <div className="Contact EmailAndPhoneWrapper">
            {item.data.emailAddress && (
              <a
                href={`mailto:${item.data.emailAddress}`}
                className={styles['icon-link']}
              >
                <MailSvg aria-hidden={true} focusable={false} width={16} />
                {item.data.emailAddress}
              </a>
            )}
            {item.data.phone && (
              <a
                href={`tel:${item.data.phone}`}
                className={styles['icon-link']}
              >
                <PhoneSvg aria-hidden={true} focusable={false} width={16} />
                {item.data.phone}
              </a>
            )}
          </div>
        )}

        {!!item.storeSells?.length && (
          <div className={styles['store-sells']}>
            <p className={styles['store-sells-label']}>
              {translations.storeAlsoSells}
            </p>
            <ul className={styles['store-sells-list']}>
              {item.storeSells.map((brand, i) => {
                return (
                  <li
                    key={`${key}-brand-${i}`}
                    className={styles['store-sells-item']}
                  >
                    <Link
                      href={brand.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles['link']}
                    >
                      {brand.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {item.data.webSite && (
          <Link
            href={item.data.webSite}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles['link'], styles['icon-link'], styles['end'])}
          >
            {translations.visitWebsite || item.data.webSite}
            <ExternalLinkSvg aria-hidden={true} focusable={false} width={16} />
          </Link>
        )}
      </AccordionItem>
    );
  };

  const searchInputId = `FindRetailers-${uid}-search-input`;

  const onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      onSubmitSearch();
    }
  };

  const radiusSelectOptions = [
    { label: '25 km', value: '25' },
    { label: '50 km', value: '50' },
    { label: '100 km', value: '100' },
  ];

  const retailersContent = (
    <div className={clsx(styles['retailers'])} aria-live="polite" aria-busy={loading}>
      {!hideSearchInputs && (
        <div
          className={clsx(
            styles['search-container'],
            classNames?.searchContainer,
          )}
        >
          <div
            className={clsx(
              styles['search-input-container'],
              classNames?.searchInput,
            )}
          >
            <fieldset className={styles['search-fieldset']}>
              <label className={styles['search-label']} htmlFor={searchInputId}>
                {translations.searchFieldLabel}
              </label>
              <input
                type="text"
                id={searchInputId}
                name="findRetailersInput"
                className={styles['search-input']}
                onChange={onChangeTerm}
                value={search}
                placeholder={translations.searchFieldPlaceholder || ''}
                aria-label={translations.searchFieldLabel || ''}
                onKeyDown={onInputKeyDown}
              />

              <button
                className={clsx(
                  sharedStyles['button-reset'],
                  styles['search-button'],
                  styles['search-clear'],
                )}
                onClick={() => clearSearch()}
                aria-label={translations.clear || ''}
                title={translations.clear || ''}
                disabled={!search?.length}
              >
                <ClearSvg focusable={false} width={16} aria-hidden />
              </button>
              <button
                type="submit"
                className={clsx(
                  sharedStyles['button-reset'],
                  styles['search-button'],
                  styles['search-submit'],
                )}
                aria-label={translations.searchSubmitLabel || ''}
                title={translations.searchSubmitLabel || ''}
                onClick={() => onSubmitSearch()}
                disabled={!search?.length}
              >
                <SearchSvg focusable={false} width={16} aria-hidden />
              </button>
            </fieldset>
            <div
              className={clsx(
                styles['search-message'],
                loading && styles['loading'],
              )}
            >
              {message}
            </div>
          </div>
          <div
            className={clsx(
              styles['search-options'],
              classNames?.searchOptions,
            )}
          >
            <Select
              options={radiusSelectOptions}
              placeholder={translations.radiusPlaceholder}
              label={translations.radiusLabel || 'Radius'}
              value={`${radius || ''}`}
              onValueChange={(value) => setRadius(parseInt(value, 10))}
              className={styles['search-radius']}
            />
            <div className={styles['radius-help-text']}>
              {translations.radiusHelpText}
            </div>
            {showInternational && (
              <Checkbox
                className={styles['international-input']}
                checked={international}
                onCheckedChange={(value) =>
                  setInternational(typeof value === 'string' ? false : value)}
              >
                {translations.otherCountriesIncluded}
              </Checkbox>
            )}
          </div>
        </div>
      )}
      <div
        className={clsx(
          styles['result-container'],
          classNames?.resultContainer,
        )}
      >
        <div className={clsx(styles['result-stores'], classNames?.resultStores)}>
          {!!stores?.length && (
            <Accordion
              onValueChange={onAccordionChange}
              className={clsx(styles['result-list'], classNames?.resultList)}
            >
              {stores.map(storeMapper)}
            </Accordion>
          )}
          {loading && <Loader className={styles['loader-list']} />}
        </div>
        <div className={clsx(styles['result-map'], classNames?.resultMap)}>
          {!geoLoading && (
            <Map
              center={coords}
              stores={stores}
              viewOnMap={translations.viewOnMap || ''}
              visitWebsiteLabel={translations.visitWebsite || ''}
              sticky
            />
          )}
          {(geoLoading || loading) && <Loader className={styles['loader-map']} />}
        </div>
      </div>
      <Head>
        <link rel="preconnect" href="https://maps.googleapis.com" />
      </Head>
    </div>
  );

  if (hideOnlineStores) {
    return (
      <section className={clsx(styles['root'], className)} style={style}>
        {retailersContent}

      </section>
    );
  }

  const onlineStoresMapper = (store: Store, index: number) => {
    if (!store.data.webSite) {
      return null;
    }
    return (
      <li
        className={styles['online-stores-item']}
        key={`Online-Store-${index}`}
      >
        <Link
          className={styles['online-stores-link']}
          href={store.data.webSite || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {store.data.companyName || store.data.webSite}
          <ExternalLinkSvg className={styles['icon-external']} aria-hidden={true} focusable={false} width={16} />
        </Link>
      </li>
    );
  };

  const onlineStoresContent = (
    <div className={styles['online-stores']} aria-live="polite" aria-busy={onlineStoresLoading}>
      {onlineStores?.length > 0
        ? (
          <ul className={styles['online-stores-list']}>
            {onlineStores.map(onlineStoresMapper)}
          </ul>
          )
        : (
          <div className={styles['online-stores-empty']}>
            {translations.noOnlineStoresFound || ''}
          </div>
          )}
      {onlineStoresLoading && <Loader className={styles['loader-online']} />}
    </div>
  );

  const tabs = [
    { label: 'Retailers', content: retailersContent },
    { label: 'Online', content: onlineStoresContent },
  ];

  return (
    <section className={clsx(styles['root'], className)} style={style}>
      <Tabs tabs={tabs} />
    </section>
  );
};
