import React from 'react';

import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import sharedStyles from '../../style/shared.module.scss';

import styles from './accordion-item.module.scss';

export interface AccordionItemClassNames {
  item?: string;
  trigger?: string;
  content?: string;
}

interface AccordionItemProps {
  className?: string;
  value: string;
  label: React.ReactNode;
  children?: React.ReactNode;
  indicator?: React.ReactNode;
  classNames?: AccordionItemClassNames;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  className,
  value,
  label,
  children,
  indicator,
  classNames,
}) => (
  <RadixAccordion.Item
    className={clsx(styles['root'], classNames?.item)}
    value={value}
  >
    <AccordionTrigger indicator={indicator} className={classNames?.trigger}>
      {label}
    </AccordionTrigger>
    <AccordionContent className={classNames?.content}>
      {children}
    </AccordionContent>
  </RadixAccordion.Item>
);

interface AccordionTriggerProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  indicator?: React.ReactNode;
}

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, className, indicator, ...props }, forwardedRef) => (
  <RadixAccordion.Header className={clsx(styles['header'])} asChild>
    <RadixAccordion.Trigger
      className={clsx(
        sharedStyles['button-reset'],
        styles['trigger'],
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
      {indicator && (
        <span className={clsx(styles['indicator'])}>{indicator}</span>
      )}
    </RadixAccordion.Trigger>
  </RadixAccordion.Header>
));

type AccordionContentProps = React.HTMLAttributes<HTMLDivElement>;

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <RadixAccordion.Content
    {...props}
    ref={forwardedRef}
    className={styles['content-container']}
  >
    <div className={clsx(styles['content'], className)}>{children}</div>
  </RadixAccordion.Content>
));
