import { AccordionStyled } from './accordion-old.styled';

// Hack due to React Accessible Accordion not exporting types
type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
type ID = string | number;
interface AccordionProps
  extends Pick<DivAttributes, Exclude<keyof DivAttributes, 'onChange'>> {
  className?: string;
  preExpanded?: ID[];
  allowMultipleExpanded?: boolean;
  allowZeroExpanded?: boolean;
  onChange?(args: ID[]): void;
}

export const AccordionOld: React.FC<AccordionProps> = ({
  children,
  ...rest
}) => {
  return <AccordionStyled {...rest}>{children}</AccordionStyled>;
};
