import React from 'react';

import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import styles from './accordion.module.scss';

import type { AccordionSingleProps } from '@radix-ui/react-accordion';

type AccordionProps = Omit<AccordionSingleProps, 'type'>;

export const Accordion: React.FC<AccordionProps> = ({
  className,
  children,
  defaultValue,
}) => {
  return (
    <RadixAccordion.Root
      className={clsx(styles['root'], className)}
      type="single"
      defaultValue={defaultValue}
      collapsible
    >
      {children}
    </RadixAccordion.Root>
  );
};
